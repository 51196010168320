export default {
	"index"           : {
		"path"       : "/",
		"title"      : "No logs secure VPN",
		"description": "Privacy oriented VPN servers installed on your own VM instances. No logs or backdoors and 100% transparency",
	},
	"contact"         : {
		"path"       : "/contact/",
		"title"      : "Contact us",
		"description": "Leave us a message and we promise to respond within the shortest time possible - no logs vpn",
	},
	"create_wireguard": {
		"path"       : "/create/wireguard/",
		"title"      : "Create Wireguard VPN server",
		"description": "Create a Wireguard server in minutes. Privacy oriented VPN servers installed on your own VM instances",
	},
	"create_openvpn"  : {
		"path"       : "/create/openvpn/",
		"title"      : "Create OpenVPN VPN server",
		"description": "Create a OpenVPN server in minutes. Privacy oriented VPN servers installed on your own VM instances",
	},
	"legal"           : {
		"path"       : "/legal/",
		"title"      : "Privacy, terms and refunds",
		"description": "DR SOFT S.R.L - RO18669955, Bucharest, Romania",
	},
	"posts"           : {
		"path"       : "/posts/",
		"title"      : "News from our team",
		"description": "nologs-vpn news and updates on VPN products and VPN industry news",
	},
	"payment"         : {
		"path"       : "/payment/",
		"title"      : "Submit payment",
		"description": "payment page for your VPN server",
	},
	"success"         : {
		"path"       : "/success/",
		"title"      : "We're creating your server",
		"description": "Payment Success page for your VPN server",
	}
};
