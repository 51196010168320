import React from 'react';
import {graphql} from "gatsby";
import Img from "gatsby-image";
import {Link} from "gatsby";
import tw, {styled, GlobalStyles} from 'twin.macro';
import Helmet from "react-helmet";
// @ts-ignore
import loadable from '@loadable/component';

import {IPageProps,} from "@/props";
import {findPageMeta} from "@/lib/utils";

const Seo = loadable(() => import ("@/components/seo"));
const Theme = loadable(() => import ("@/components/theme"));
const Nav = loadable(() => import ("@/components/nav"));
const Hero = loadable(() => import ("@/components/pages/other/hero"));
const LegalIll = loadable(() => import ("@/components/illustrations/legal"));

const Features = loadable(() => import ("@/components/features"));
const Faq = loadable(() => import ("@/components/faq"));
const Footer = loadable(() => import ("@/components/footer"));

const Wrapper = styled.div(
	() => [
		tw``
	]
);

interface Props extends IPageProps {

}

const Posts: React.FunctionComponent<Props> = (props: Props) => {
	const meta = findPageMeta(props.path);

	return (
		<Theme>
			<GlobalStyles/>
			<Helmet bodyAttributes={{class: 'font-body'}}/>
			<Seo {...props}/>

			<Wrapper>
				<div className="bg-primary-background">
					<Nav/>
					<Hero
						title={meta.title}
						subtitle={meta.description}
						indicator={"/news"}
						illustration={<LegalIll
							height={"100%"}
							width={"100%"}
						/>}
					/>
				</div>

				<div className="relative bg-bg-background pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
					<div className="relative max-w-7xl mx-auto">
						<div className="grid gap-8 max-w-lg mx-auto lg:grid-cols-3 lg:max-w-none">
							{props.data.allMarkdownRemark?.edges?.map((edge, i) => {
								return (
									<Link
										key={`post-${i}`}
										to={`/${edge.node?.frontmatter?.slug}`}
										className="bg-white flex flex-col rounded-lg shadow-lg overflow-hidden"
									>
										{edge.node?.frontmatter?.hero?.childImageSharp.fluid && (
											<div className="flex-1 flex-shrink-0">
												<Img fluid={edge.node?.frontmatter?.hero?.childImageSharp.fluid}/>
											</div>
										)}
										<div className="bg-white p-6 flex flex-col justify-between">
											<div>
												<h4 className="mt-2 text-xl leading-7 font-semibold text-primary-background">
													{edge.node?.frontmatter?.title}
												</h4>
												<p className="mt-3 text-base text-justify leading-6 text-gray-500">
													{edge.node?.excerpt}
												</p>
											</div>

											<div className="flex-1 flex mt-4">
												<p className="flex-grow text-sm leading-5 font-medium text-gray-400">
													{edge.node?.frontmatter?.date}
												</p>

												<p className="flex-grow text-right text-sm leading-5 font-mono font-extrabold text-secondary-background">
													/{edge.node?.frontmatter?.category}
												</p>
											</div>
										</div>
									</Link>
								);
							})}
						</div>
					</div>
				</div>

				<div className="max-w-2xl lg:py-24 py-12 mx-auto pb-16 mt-12 sm:pb-0 px-4 md:px-0 lg:max-w-screen-xl">
					<div className="max-w-4xl mx-auto text-center">
						<h2 className="text-3xl leading-9 font-extrabold text-primary-background">
							Unlimited users and zero restrictions
						</h2>
						<p className="mt-4 text-lg leading-7 text-gray-500">
							Your VM is like any other server and it can be upgraded to fit any scenario.
							We install only open source software which has no limitations in place, it is
							upgradable and highly customizable
						</p>
					</div>
					<Features/>
				</div>

				<div className="bg-bg-background mt-20 px-4 md:px-0">
					<div className="max-w-2xl mx-auto py-16 sm:py-24 sm:px-6 lg:max-w-screen-xl lg:px-8">
						<Faq/>
					</div>
				</div>

				<Footer/>
			</Wrapper>
		</Theme>
	);
};

export default Posts;

export const pageQuery = graphql`
	query {
		allMarkdownRemark(
			sort: { order: DESC, fields: [frontmatter___date] },
			filter: {
				frontmatter: {
					draft: {
						ne: true
					}
				}
			}
		) {
			edges {
				node {
					id
					excerpt(pruneLength: 250)
						
					frontmatter {
						date(formatString: "MMMM DD, YYYY")
						slug
						title
						category
						hero {
							childImageSharp {
								fluid(maxWidth: 820, quality: 100) {
									...GatsbyImageSharpFluid_tracedSVG
								}
							}
						}
					}
				}
			}
		}
	}
`;
