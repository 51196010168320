// @ts-ignore
import meta from "@/lib/page-meta";

export const ensureForwardSlash = (path: string) => {
	path += path.endsWith("/") ? "" : "/";
	return path;
};

export const ensureSlashes = (path: string) => {
	path = (path.startsWith("/") ? "" : "/") + path;
	return ensureForwardSlash(path);
};

export const findPageMeta = (path: string) => {
	path = ensureSlashes(path);
	const keys = Object.keys(meta);
	for (let i = 0; i < keys.length; i++) {
		const page = meta[keys[i]];
		if (page.path === path) {
			return page;
		}
	}
	return null;
};

export const compareValues = (key: string, order = 'asc') => {
	return function innerSort(a: any, b: any) {
		if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
			return 0;
		}

		const varA = (typeof a[key] === 'string') ? a[key].toUpperCase() : a[key];
		const varB = (typeof b[key] === 'string') ? b[key].toUpperCase() : b[key];

		let comparison = 0;
		if (varA > varB) {
			comparison = 1;
		} else if (varA < varB) {
			comparison = -1;
		}
		return ((order === 'desc') ? (comparison * -1) : comparison);
	};
};
